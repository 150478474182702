import {provideStore} from "@ngxs/store";
import {environment} from "environments/environment";
import {withNgxsFormPlugin} from "@ngxs/form-plugin";
import {withNgxsReduxDevtoolsPlugin} from "@ngxs/devtools-plugin";
import {importProvidersFrom} from "@angular/core";
import {provideRouter} from "@angular/router";
import {
  provideInterfaceSize,
} from "@12stz/ui-kit/quarks/providers";
import {provideToast} from "@12stz/ui-kit/organisms/toast";
import {AuthGuard, AuthSdkModule} from '@12stz/auth-sdk';
import {init} from '@sentry/angular-ivy';
import {createSentryConfig, SentryModule} from "configuration";
import { provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import {provideAnimations} from "@angular/platform-browser/animations";
import {RequestType, Request} from '@12stz/auth-sdk/lib/interfaces/config.interface';

init(createSentryConfig(environment.production));

const shellRoute = {
  path: '',
  canActivate: [AuthGuard],
  data: {
    roles: ['access'],
  },

  loadChildren: () =>
    import('./app/shell/shell.module').then((m) => m.ShellModule),
}

const excludedRequests: Request[] = [
  { type: 'POST' as RequestType, match: 'winner/calculate' },
  { type: 'POST' as RequestType, match: 'winner/intervals' }
]

export const providers = [
  /*provideKeycloakAuth({
    clientId: environment.clientId,
    url: environment.authServerUrl,
  }, excludedRequests),*/
  importProvidersFrom(
    AuthSdkModule.forRoot({
      clientId: environment.clientId,
      authServerUrl: environment.authServerUrl,
      excludedRequests,
    })
  ),
  importProvidersFrom(
    SentryModule.forRoot(environment.production),
  ),
  provideStore([], { developmentMode: !environment.production}),
  withNgxsFormPlugin(),
  withNgxsReduxDevtoolsPlugin(),
  provideInterfaceSize('medium'),
  provideToast({
    vertical: 'top',
    horizontal: 'center',
    duration: 15000,
  }),
  provideRouter([shellRoute]),
  provideAnimations(),
  provideHttpClient(withInterceptorsFromDi()),
]
